.App {
  background-color: #1a1c21;
  padding: 1rem;
  text-align: center;
}

.App-logo {
  display: inline;
  height: 1.5em;
  margin-right: 0.25rem;
  padding-right: 0;
  pointer-events: none;
}

.App-header {
  background-color: #1a1c21;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}
